import React from "react"
import Layout from "../../layouts/default"

import Breadcrumb from "../../components/breadcrumb"
import Paragraph from "../../components/paragraph"
import Heading from "../../components/heading"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Stack from "../../components/stack"
import TextLink from "../../components/textlink"

const NeuigkeitenHoerl = () => {
  return (
    <Layout backdrop="aussen">
      <Seo
        title="Praktikum im Hölderlinturm"
        description="Du interessierst dich für Literatur, bist aufgeschlossen, arbeitest gerne mit Menschen zusammen und möchtest mehr über die Arbeit eines Literaturmuseums erfahren? Dann bist du vielleicht genau die/der Richtige für ein Freiwilliges Soziales Jahr im Museum Hölderlinturm!"
      />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Mitarbeiten",
                link: "/mitarbeiten",
              },
              {
                title: "Praktikum",
                link: "/mitarbeiten/praktikum",
              },
            ]}
          />
          <PageTitle>Praktikum im Hölderlinturm</PageTitle>

          <Paragraph dropcap={true}>
            Du studierst Germanistik, Geschichte, Kulturwissenschaft oder ein
            vergleichbares Fach und möchtest Einblicke in die Praxis eines
            Literaturmuseums erhalten? Dann bewirb dich für ein Praktikum im
            Hölderlinturm! Wir bieten Praktikumsplätze für die Dauer von 6
            Wochen bis zu 6 Monaten an.
          </Paragraph>
        </Stack>
        <Stack>
          <Heading as="h2" level={3}>
            Was erwartet dich?
          </Heading>
          <Paragraph>
            Bei einem Praktikum im Hölderlinturm wirst du in viele Projekte des
            Museums mit eingebunden. Abhängig vom aktuellen Programm des Museums
            unterstützt du zum Beispiel Recherchen zu Ausstellungs- und
            Vermittlungsprojekten und deren Umsetzung, übernimmst die Redaktion
            von Katalog- und Ausstellungstexten, hilfst bei der Vorbereitung und
            Durchführung von Veranstaltungen oder entwickelst Inhalte für die
            Social-Media-Kanäle und Vermittlungsangebote des Museums. Bei alldem
            erhältst du Anleitungen, regelmäßige Hilfestellungen und
            Rückmeldungen, die dir dabei helfen, deine Praktikumsprojekte
            umzusetzen. Du lernst Methoden, Ansätze und Abläufe der
            Museumsarbeit kennen und sammelst eigene Erfahrungen in den
            Bereichen Ausstellungskonzeption, Ausstellungsvermittlung und
            Kulturmanagement. Dabei kannst du dich selbst mit deinen Interessen,
            Fähigkeiten und Studienschwerpunkten mit einbringen.{" "}
          </Paragraph>
        </Stack>

        <Stack>
          <Heading as="h2" level={3}>
            Interesse?
          </Heading>
          <Paragraph>
            Dann sende uns eine Mail mit deinem Lebenslauf, einem kurzen
            Anschreiben und dem Wunschtermin für dein Pratkikum an{" "}
            <TextLink href="mailto:hoelderlinturm@tuebingen.de">
              hoelderlinturm@tuebingen.de
            </TextLink>
          </Paragraph>
          <Paragraph>
            Ansprechpartner: Florian Mittelhammer (Leitender Kurator)
          </Paragraph>
          <Paragraph>Wir freuen uns über deine Bewerbung!</Paragraph>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default NeuigkeitenHoerl
